//TODO: clean up the upload function, hard to read
//and getting maybe unhandled exception errors
function UploaderFactory($rootScope, jobSubmit, $timeout, $http, $log, $q, $window, Upload, userTokens,
SETTINGS) {
  const _url = SETTINGS.apiEndpoint + 'jobs/upload/';
  // this corresponds to the s3 aws sdk event name
  // if we employ any other api's, we will still send this event to reduce confusion
  const socketEvent = 'httpDownloadProgress';

  const uploader = {
    allUploadedEvent : 'sq.jobs.upload.service:uploadsComplete',
    running : false,
    // Files we have selected
    files: [],
    filesWithError: {},
  };

  uploader.listen = listen;

  uploader.upload = (files, parentJobData) => {
    // if(uploader.files.length) {
    //   const err = new Error('Attempted to upload, but upload already in progress');
    //   $log.error(err);
    //   return $q.reject(err);
    // }

    if(!Array.isArray(files)) {
      const err = new Error('Files must be array. Use a multiple or ngf-multiple="true" attr');
      $log.error(err);
      return $q.reject(err);
    }

    if(!parentJobData || typeof parentJobData !== 'object') {
      const err = new Error('parentJob object required');
      $log.error(err);
      return $q.reject(err);
    }

    uploader.files = files;

    if(!uploader.files.length) {
      const err = new Error('No files provided to Uploader');
      $log.error(err);
      return $q.reject(err);
    }

    const _numFiles = uploader.files.length;
    let _numUploadedOrDead = 0;

    const deferred = $q.defer();

    // // We'll ask jobSubmit to store these jobs
    let jobSubmitPromises = [];
    uploader.running = true;

    files.forEach( (file, idx) => {
      let jobData;

      // The first file will use the parentJob data
      // The others will be spawns/forks from this job
      // We call them "spawns" rather than forks, because
      // in the future we will allow forking of completed jobs
      // Those will be distinct in that they will branches from a complete result
      jobData = jobSubmit.spawn();
      jobData.setFileName(file.name);

      if(file.type == 's3') {
        if(file.cancelQuery) {
          file.cancelQuery.resolve();
        }

        file.cancelQuery = $q.defer();

        const fileData = Object.assign({}, file);
        delete fileData.deferredAbort;
        delete fileData.cancelQuery;

      file.upload = $http.post(_url, {file: fileData, job: jobData}, { timeout: file.cancelQuery.promise });

//         $http({
//           method: 'POST',
//           url: _url,
//           data: {file, job: jobData},
//           config: {timeout: file.deferredAbort.promise},
//         });

//         $timeout( () => {
// file.deferredAbort.resolve();
//         },1000, false)

      } else {
        file.upload = Upload.upload({
          url: _url,
          // doesn't automatically store as JSON
          data: {file, job: JSON.stringify(jobData)},
          headers: {
            Authorization: `Bearer ${userTokens.idToken}`,
          }
        });
      }

      file.upload.then((response) => {
        file.data = response.data;
        file.progress = 100;
        jobSubmitPromises.push( jobSubmit.submitAsync(response.data) );
      }, (rejection) => {
        if(rejection.status > 0) {
          $log.error(`${rejection.status}: ${rejection.data}`);

          file.serverError = `${rejection.status}: ${rejection.data}`;

          uploader.filesWithError[idx] = `${rejection.status}: ${rejection.data}`;
        }
        file.progress = 0;
      },
      // Only uploading form local file will trigger this; we set socket.io
      // listeners for everything else
      (event) => {
        // TOOD: combine function with progress timer below
        // Using timeout canceller ends up having us miss events
        // if(file.timeoutCanceller) {
        //   $timeout.cancel(file.timeoutCanceller);
        // }

        // $timeout(() => {
          // I don't belive this actually needs an apply or eval async,
          // because it uses $http which triggers digests automatically
          $rootScope.$evalAsync( () => {
            $window.requestAnimationFrame(() => {
              file.progress = event.loaded * 100 / event.total;
            });
          });
        // }, 100);
      }).catch( (err) => {
        $log.error(err);
        file.progress = 0;
      }).finally( () => {
        _numUploadedOrDead++;

        if(_numUploadedOrDead === _numFiles) {
          $q.all(jobSubmitPromises).then(() => {
            deferred.resolve();
          }, (arrayOfFailure) => {
            $log.debug('cancelled or failed upload');
            deferred.reject(arrayOfFailure);
          }).finally(() => {
            $rootScope.$broadcast(uploader.allUploadedEvent,
              jobSubmitPromises.length === 0 ? null : jobSubmitPromises);

            uploader.running = false;
            uploader.files = [];
            uploader.filesWithError = {};
          });
        }
      });
    });

    return deferred.promise;
  };

  uploader.cancelUpload = (file) => {
    if(file.type === 's3') {
      if(file.cancelQuery) {
        file.cancelQuery.resolve();
      }
      // file.upload.resolve();
    } else {
      file.upload.abort();
    }

    const index = uploader.files.indexOf(file);

    if(index === -1) {
      $log.error("Couldn't find cancelled file in uploader file list");
      return;
    }

    uploader.files.splice(index, 1);
  };

  function _setSocketIOlisteners(socket) {
    $log.debug('setting socket io listeners in jobs.upload.service.js');

    let timeoutPromise;
    socket.on(socketEvent, (response) => {
      $rootScope.$evalAsync(() => {
        const data = JSON.parse(response);
        //TODO: improve performance
        uploader.files.forEach((file) => {
          if(file.name === data.data.name) {
            // We could technically use file.Size as the denominator,
            // but by sending data: total we keep a consistent file upload api
            $window.requestAnimationFrame( () => {
              file.progress = data.data.loaded * 100 / data.data.total;
            });
          }
        });
      });
    });
  }

  // could be dangerous; assumes only this servce listens to these events
  function _removeListeners(socket) {
    socket.removeAllListeners(socketEvent);
  }

  function listen() {
    $rootScope.$on('sq.services.socketIO:unauthorized', (event, socket) => {
      $log.debug('sq.services.socketIO:unauthorized in job.upload.service');
      _removeListeners(socket);
    });

    $rootScope.$on('sq.services.socketIO:authenticated', (event, socket) => {
      $log.debug('sq.services.socketIO:authenticated in job.upload.service');
      _removeListeners(socket);
      _setSocketIOlisteners(socket);
    });

    $rootScope.$on('sq.services.socketIO:disconnected', (event, socket) => {
      $log.debug('sq.services.socketIO:disconnected in job.upload.service');

      _removeListeners(socket);
    });
  }

  return uploader;
}

angular.module('sq.jobs.upload.service', ['ngFileUpload', 'sq.user.auth.tokens',
'sq.jobs.submit.service',
'sq.config'])
.factory('Uploader', UploaderFactory);